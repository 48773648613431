import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import DateField from '@/shared/fields/date-field';
import { GenericModel } from '@/shared/model/generic-model';
import FilesField from '@/shared/fields/files-field';
import BooleanField from '@/shared/fields/boolean-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.projectReport.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.projectReport.enumerators.${name}.${value}`);
}
const fields = {
  id: new IdField('id', label('id')),
  reportFile: new FilesField(
    'reportFile',
    label('reportFile'),
    Storage.values.projectReportReportFile,
    Permissions.getFilePermissions(
      'projectReportFileFields',
      'reportFile',
    ),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  internalReportFile: new FilesField(
    'internalReportFile',
    label('internalReportFile'),
    Storage.values.projectReportInternalReportFile,
    Permissions.getFilePermissions(
      'projectReportFileFields',
      'internalReportFile',
    ),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  projectDurationExtension: new BooleanField(
    'projectDurationExtension',
    label('projectDurationExtension'),
    {
      noLabel: i18n('entities.projectReport.enabled'),
      yesLabel: i18n('entities.projectReport.disabled'),
    },
  ),

  grantorsExtensionApprovalFile: new FilesField(
    'grantorsExtensionApprovalFile',
    label('grantorsExtensionApprovalFile'),
    Storage.values.projectReportGrantorsExtensionApprovalFile,
    Permissions.getFilePermissions(
      'projectReportFileFields',
      'grantorsExtensionApprovalFile',
    ),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  newEndDate: new DateField('newEndDate', label('newEndDate'), {
    required: true,
  }),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'inProgress',
        label: enumeratorLabel('status', 'inProgress'),
      },
      {
        id: 'completed',
        label: enumeratorLabel('status', 'completed'),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectReportModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
