<template>
  <div>
    <br />
    <div
      class="app-page-spinner"
      v-if="loading"
      v-loading="loading"
    ></div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-file
        :label="fields.reportFile.label"
        :value="presenter(record, 'reportFile')"
        :permissions="fields.reportFile.permissions"
      ></app-view-item-file>

      <app-view-item-file
        :label="fields.internalReportFile.label"
        :value="presenter(record, 'internalReportFile')"
        :permissions="
          fields.internalReportFile.permissions
        "
      ></app-view-item-file>

      <!--  <app-view-item-text
        :label="fields.projectDurationExtension.label"
        :value="presenter(record, 'projectDurationExtension')"
      ></app-view-item-text> -->

      <app-view-item-file
        :label="fields.grantorsExtensionApprovalFile.label"
        :value="presenter(record, 'grantorsExtensionApprovalFile')"
        :permissions="
          fields.grantorsExtensionApprovalFile.permissions
        "
      ></app-view-item-file>

      <app-view-item-text
        :label="fields.newEndDate.label"
        :value="presenter(record, 'newEndDate')"
      ></app-view-item-text>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectReportModel } from '@/modules/project-report/project-report-model';

const { fields } = ProjectReportModel;

export default {
  name: 'app-project-report-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectReport/view/record',
      loading: 'projectReport/view/loading',
      projectReportId: 'project/form/projectReportId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectReportId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectReport/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectReportModel.presenter(record, fieldName);
    },
  },
};
</script>
